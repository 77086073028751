import ajax, { AjaxPromise, ExtraFetchParams } from '@ajax';
import * as models from '../model/models';

export interface ParamsWebChannelSettingGet {
  /**
   * 客户网站唯一标识
   */
   appKey: string
}

export class WebChannelSettingControllerApi {
  protected $basePath = '/java-im-scheduler-service'.replace(/\/$/, '')

  public constructor(basePath?: string) {
    if (basePath !== undefined) {
      this.$basePath = basePath.replace(/\/$/, '')
    }
  }

  public webChannelSettingGet = (
    params: ParamsWebChannelSettingGet,
    opt?: ExtraFetchParams
  ): AjaxPromise<models.ApiResponse> => {
    const path = '/web-channel-setting';
    const url = this.$basePath + path;
    const p: any = {};
    p.query = {...params}
    return ajax.ajax({
      ...opt,
      method: 'GET',
      url,
      ...p
    });
  }
}

export default new WebChannelSettingControllerApi()