import ajax, { AjaxPromise, ExtraFetchParams } from '@ajax';
import * as models from '../model/models';

export interface ParamsApiMessageReadMsg {
  conversationId?: string,
  fromUserId?: string
}

export class MessageReadMsgApi {
  protected $basePath = '/java-chat-service'.replace(/\/$/, '')

  public constructor(basePath?: string) {
    if (basePath !== undefined) {
      this.$basePath = basePath.replace(/\/$/, '')
    }
  }

  public apiMessageReadMsgPut = (
    params: ParamsApiMessageReadMsg,
    opt?: ExtraFetchParams
  ): AjaxPromise<models.ApiResponse> => {
    const path = '/api/message/read/msg';
    const url = this.$basePath + path;
    const p: any = {};
    p.query = {...params}
    return ajax.ajax({
      ...opt,
      method: 'PUT',
      url,
      ...p
    });
  }
}

export default new MessageReadMsgApi()