import ajax, { AjaxPromise, ExtraFetchParams } from '@ajax';
import * as models from '../model/models';

export interface ParamsApiMessageSendPost {
  /**
   * 公司账户
   */
  account: string,
  /**
  * 自定义消息类型
  */
  action: string,
  /**
  * 消息内容
  */
  content: string,
  /**
  * 对话id
  */
  conversationId?: string,
  /**
  * 附加内容
  */
  extra?: string,
  /**
  * content的内容格式
  */
  format: string,
  /**
  * 消息ID
  */
  id?: string,
  /**
  * 消息接收者账号
  */
  receiver: string,
  /**
  * 接收者角色
  */
  receiverRole: string,
  /**
  * 消息发送者账号
  */
  sender: string,
  /**
  * 发送者角色
  */
  senderRole: string,
  /**
  * 消息标题
  */
  title?: string,
}

export class MessageControllerApi {
  protected $basePath = '/java-im-service'.replace(/\/$/, '')

  public constructor(basePath?: string) {
    if (basePath !== undefined) {
      this.$basePath = basePath.replace(/\/$/, '')
    }
  }

  public apiMessageSendsPost = (
    params: Array<ParamsApiMessageSendPost>,
    opt?: ExtraFetchParams
  ): AjaxPromise<models.ApiResponse> => {
    const path = '/api/message/sends';
    const url = this.$basePath + path;
    const p: any = {};
    p.data = params
    return ajax.ajax({
      ...opt,
      method: 'POST',
      url,
      ...p
    });
  }
}

export default new MessageControllerApi()