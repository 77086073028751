import ajax, { AjaxPromise, ExtraFetchParams } from '@ajax';
import * as models from '../model/models';

export interface ParamsApiLeaveMessageAddLeaveMessage {
  /**
   * 会话id
   */
  conversationId?: string,
  /**
   * /公司
   */
  account?: string,
  /**
   * 地区
   */
   address?: string,
  /**
   * 接入渠道
   */
  channel?: string,
  /**
   * IP
   */
  ipAddress?: string,
  /**
   * 留言消息类型
   */
  leaveMessageType?: string,
  /**
   * 公司名称
   */
  messageAccountName?: string,
  /**
   * 手机
   */
  phone?: string,
  /**
   * 问题描述
   */
  questionDesc?: string,
}

export class LeaveMessageAddLeaveMessageApi {
  protected $basePath = '/java-chat-service'.replace(/\/$/, '')

  public constructor(basePath?: string) {
    if (basePath !== undefined) {
      this.$basePath = basePath.replace(/\/$/, '')
    }
  }

  public apiLeaveMessageAddLeaveMessagePost = (
    params: ParamsApiLeaveMessageAddLeaveMessage,
    opt?: ExtraFetchParams
  ): AjaxPromise<models.ApiResponse> => {
    const path = '/api/leaveMessage/addLeaveMessage';
    const url = this.$basePath + path;
    const p: any = {};
    p.data = {...params}
    return ajax.ajax({
      ...opt,
      method: 'POST',
      url,
      ...p
    });
  }
}

export default new LeaveMessageAddLeaveMessageApi()