<template>
  <div id="app">
    <div
      v-if="loadView"
      style="width: 100%;height: 100%;"
    >
      <router-view />
    </div>
  </div>
</template>

<script>
import { JAVAIMSCHEDULERSERVICE } from './api'
import { EventBus, getUrlParams, getIPLocation } from '@utils/common.js'
import { SCHEDULE_RRESULT, USER_TYPE, EVENT_ACTIONS } from '@utils/enums/im.js'
import WebSDK from '@dustess/customer-service-im-js-sdk'
export default {
  data () {
    return {
      socket: null,
      documentVisible: 'visible',
      urlReferer: '',
      sourceEvent: null,
      loadView: false
    }
  },
  computed: {
    chatConfig () {
      return this.$store.state.chatConfig
    },
    chatInfo () {
      return this.$store.state.chatInfo
    }
  },
  async created () {
    this.getUrlReferer()
    // 与入口进行postmessage通信
    window.addEventListener('message', this.acceptPostMessage)

    await this.getWebChannelSetting()
    await Promise.all([this.getSystemSetting(this.chatConfig.account), this.getIPLocation()])
    this.getCsOnlineStatus(this.chatConfig.account)
    this.initVisible()

    EventBus.$on('closeChatWindow', this.sendPostMessage)
    EventBus.$on('connectChatWindow', this.initSocket)
    EventBus.$on('closeChatSocket', this.closeSocket)
  },
  beforeDestroy () {
    window.removeEventListener('message', this.acceptPostMessage)
    EventBus.$off('closeChatWindow', this.sendPostMessage)
    EventBus.$off('connectChatWindow', this.initSocket)
    EventBus.$off('closeChatSocket', this.closeSocket)
  },
  methods: {
    // 获取ip和地址
    async getIPLocation () {
      try {
        const { result } = await getIPLocation()
        const adInfo = result?.ad_info || {}
        const { nation, province, city, district } = adInfo
        this.setChatInfo({
          ipAddress: result?.ip,
          address: `${nation || ''},${province || ''},${city || ''},${district || ''}`
        })
      } catch (error) {
        throw Error(error)
      }
    },
    // 已读消息
    async readedMsg () {
      if (this.chatInfo.conversationId && this.chatInfo.visitorId) {
        await this.$fetchHelper(JAVACHATSERVICE.MessageReadMsgApi.apiMessageReadMsgPut, {
          conversationId: this.chatInfo.conversationId,
          fromUserId: this.chatInfo.visitorId,
          account: this.chatConfig.account
        })
      }
    },
    // 获取网站配置
    async getWebChannelSetting () {
      const { appKey = '' } = getUrlParams()
      let chatConfig = { webDetailInfoVO: { baseSetting: { themeColor: '#1990FF' } } }
      try {
        const { data, code } = await JAVAIMSCHEDULERSERVICE.WebChannelSettingControllerApi.webChannelSettingGet({ appKey })
        if (code === 500200 && data) {
          chatConfig = data
          if (data.webBaseInfoVO?.groupReception === 0) {
            this.$router.replace({ path: '/link-failure' })
          }
        }
      } catch (error) {
      }
      this.$store.dispatch('setChatConfig', Object.assign({}, chatConfig))
    },
    // 获取系统配置
    async getSystemSetting (account) {
      let chatConfig = {}
      try {
        const { data, code } = await JAVAIMSCHEDULERSERVICE.SystemSettingControllerApi.systemSettingGet({ account })
        if (code === 500200 && data) {
          chatConfig = {
            leaveMessage: data.leaveMessage
          }
        }
      } catch (error) {
      }
      this.$store.dispatch('setChatConfig', Object.assign({}, this.chatConfig, chatConfig))
    },
    // 获取是否有在线客服
    async getCsOnlineStatus (account) {
      let openSocket = false
      try {
        const { data, code } = await JAVAIMSCHEDULERSERVICE.GetCsOnlineStatusControllerApi.getCsOnlineStatusGet({ account })
        if (code === 500200 && data) {
          openSocket = true
        }
      } catch (error) {
      }
      this.$store.dispatch('setOpenSocket', openSocket)
      this.loadView = true
      if (openSocket || (!openSocket && !this.chatConfig.leaveMessage?.isEnable)) {
        this.initSocket()
      }
    },
    initVisible () {
      document.addEventListener('visibilitychange', () => {
        this.documentVisible = document.visibilityState
        if (document.visibilityState === 'visible') {
          // 通知已读
          this.readedMsg()
        }
      })
    },
    initSocket () {
      if (this.socket) {
        return
      }
      const { landingPage = '', acceptType = '' } = getUrlParams()
      // 初始化socket并绑定账户信息
      this.socket = new WebSDK({ url: this.$env.VUE_APP_WEBSOCKET_KF_URL + '/ws-kf-web' })
      this.socket.onBindAccount({
        user: this.chatInfo.visitorId,
        account: this.chatConfig.account,
        type: USER_TYPE['client'],
        // webType: String(this.chatConfig.webType),
        landingPage,
        acceptType: acceptType || '1',
        // 0-搜索引擎 1-直接访问 2-未知来源
        accessSource: acceptType || '2',
        ipAddress: this.chatInfo.ipAddress,
        address: this.chatInfo.address
      })
      // 监听socket消息
      this.socket.on('wsResResult', (message) => {
        const acceptMsg = JSON.parse(message)
        let newMsg = null
        this.$store.dispatch('setChatAction', acceptMsg.action || '')
        // 系统调度信息
        if (acceptMsg.action === EVENT_ACTIONS['schedule']) {
          const acceptMsgContent = JSON.parse(acceptMsg.content)
          this.setChatInfo({ status: acceptMsgContent.schedulerResult, kfId: acceptMsgContent.qwUId || '', kfName: acceptMsgContent.qwNickName || '', avatar: acceptMsgContent.headImg || '', conversationId: acceptMsgContent.conversationId || '' })
          if (acceptMsgContent.content) {
            newMsg = {
              conversationId: '',
              fromUserId: '',
              fromUserName: '',
              fromUserType: 3,
              uniqueMsgId: acceptMsg.id,
              toUserId: acceptMsg.receiver,
              sendTime: Date.now(),
              success: true,
              loading: false,
              isRead: true,
              withdraw: false,
              content: JSON.stringify({ msgType: 'text', data: acceptMsgContent.content })
            }
          }

          // 如果是客服接入的调度，把缓存的消息都发过去
          if (acceptMsgContent.schedulerResult === SCHEDULE_RRESULT['SUCCESSFUL']) {
            EventBus.$emit('sendQuequeMsg')
          }

          // 如果是暂无在线客服的调度，关闭socket连接
          if (acceptMsgContent.schedulerResult === SCHEDULE_RRESULT['UNAVAILABLE']) {
            this.closeSocket()
          }
        }

        // 系统消息
        if (acceptMsg.action === EVENT_ACTIONS['system']) {
          newMsg = {
            conversationId: '',
            fromUserId: '',
            fromUserName: '',
            fromUserType: 3,
            uniqueMsgId: acceptMsg.id,
            toUserId: acceptMsg.receiver,
            sendTime: Date.now(),
            success: true,
            loading: false,
            isRead: true,
            withdraw: false,
            content: acceptMsg.content
          }
        }

        // 客服消息
        if (acceptMsg.action === EVENT_ACTIONS['msg']) {
          // 给sdk ui发送消息提醒
          const message = JSON.stringify({ type: 'message' })
          this.sendPostMessage(message)
          newMsg = {
            conversationId: acceptMsg.conversationId,
            fromUserId: acceptMsg.sender,
            fromUserName: this.chatInfo.kfName,
            fromUserType: 1,
            uniqueMsgId: acceptMsg.id,
            toUserId: acceptMsg.receiver,
            sendTime: Date.now(),
            success: true,
            loading: false,
            isRead: true,
            withdraw: false,
            content: acceptMsg.content
          }
          if (this.documentVisible === 'visible') {
          // 通知已读
            this.readedMsg()
          }
        }

        // 消息撤回
        if (acceptMsg.action === EVENT_ACTIONS['withdraw']) {
          const chatRecordList = JSON.parse(JSON.stringify(this.$store.state.chatRecordList))
          for (let record of chatRecordList) {
            if (record.uniqueMsgId === acceptMsg.content && record.conversationId === acceptMsg.conversationId) {
              record.withdraw = true
              break
            }
          }
          this.$store.dispatch('setChatRecordList', chatRecordList)
        }

        // 消息已读
        if (acceptMsg.action === EVENT_ACTIONS['is-read']) {
          const chatRecordList = JSON.parse(JSON.stringify(this.$store.state.chatRecordList))
          chatRecordList.forEach(item => {
            item.isRead = true
          })
          this.$store.dispatch('setChatRecordList', chatRecordList)
        }

        // socket断开
        if (acceptMsg.action === EVENT_ACTIONS['offline'] || acceptMsg.action === EVENT_ACTIONS['close-session']) {
          this.closeSocket()
        }
        if (newMsg) {
          const chatRecordList = [...this.$store.state.chatRecordList]
          chatRecordList.push(newMsg)
          this.$store.dispatch('setChatRecordList', chatRecordList)
          EventBus.$emit('newMessage')
        }
      })
    },
    setChatInfo (chatInfo) {
      this.$store.dispatch('setChatInfo', Object.assign({}, this.chatInfo, chatInfo))
    },
    closeSocket () {
      this.socket && this.socket.close()
      this.socket = null
      // ipAddress, address由接口获取，不能重置  visitorId也不能重置
      this.$store.dispatch('setChatInfo', {
        ...this.chatInfo,
        kfId: '',
        status: '',
        kfName: '',
        avatar: '',
        conversationId: ''
      })
      this.$store.dispatch('setChatAction', EVENT_ACTIONS['offline'])
    },
    acceptPostMessage (event) {
      if (event.origin === this.urlReferer) {
        this.sourceEvent = event
        event.data === 'openChat' && (EventBus.$emit('scrollChatContent'))
      }
    },
    getUrlReferer () {
      const params = getUrlParams()
      this.urlReferer = decodeURIComponent(params.referer || '')
    },
    sendPostMessage (msg) {
      this.sourceEvent && this.sourceEvent.source.postMessage(msg, this.urlReferer || '*')
    }
  }
}
</script>

<style lang="scss">
// position overflow解决ios页面可以拖动bug
html, body, #app{
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
#app{
  box-sizing: border-box;
  padding-bottom: constant(safe-area-inset-bottom); /*兼容 IOS<11.2*/
  padding-bottom: env(safe-area-inset-bottom); /*兼容 IOS>11.2*/
}
</style>
